import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 1,
  "title": "Get ready for production"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Once you have developed and tested your application to work with test user accounts, you are ready to move ahead and work with real user eID accounts.`}</p>
    <p><em parentName="p">{`Please note`}</em>{` that for production usage, you do need a paid subscription.`}</p>
    <p>{`Follow these steps to get ready for production:`}</p>
    <ol>
      <li parentName="ol">{`If you haven't done so already, go to the `}<a parentName="li" {...{
          "href": "https://dashboard.criipto.com/billing"
        }}>{`dashboard`}</a>{`, select the billing tab and choose a plan that suits your expected usage.`}</li>
      <li parentName="ol">{`Next, you must acquire a production certificate for the eID provider(s) you wish to use in production. More on how to do that `}<a parentName="li" {...{
          "href": "/verify/e-ids"
        }}>{`can be found here`}</a>{`.`}</li>
      <li parentName="ol">{`Make sure the Production/Test toggle at the top center is set to "PRODUCTION".`}</li>
      <li parentName="ol">{`Go to the "Domains" tab and set up a production domain.`}
        <ul parentName="li">
          <li parentName="ul">{`Note that if you choose a domain that ends with something other than `}<inlineCode parentName="li">{`criipto.id`}</inlineCode>{`, we recommend going with the Criipto managed option ("Managed SSL certificate") for SSL certificate.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Go to the "Applications" tab and register your production application:`}
        <ul parentName="li">
          <li parentName="ul">{`The Client ID must be unique; it cannot be the same as any of your other applications`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Configure your production application with the new domain, Client ID, and Client secret. `}</li>
    </ol>
    <p>{`Once you have been through these steps, you are ready to start using real Swedish BankID, Danish MitID, and others in your production application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      